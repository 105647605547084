import React, { Component } from 'react';
import Nav from './Nav.js'
import './App.css';

class App extends Component {

  render() {
    return (
      <Nav />
    );
  }
}

export default App;